import { ComponentPropsWithRef } from "react";
import styles from "../AlgoliaSearch.module.scss";
import { Tooltip } from "@mui/material";

type SampleFileButtonProps = ComponentPropsWithRef<"button">;

function SampleFileButton({ ...props }: SampleFileButtonProps) {
  const handleSampleFile = () => {
    const sampleFile = [
      ["Product Details", "Packaging", "Target Price", "Limit"],
    ];
    const csvContent = sampleFile.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "algolia_input_sample.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Tooltip title="Use this file to write queries in">
      <button
        className={styles.commonBtn}
        type="button"
        onClick={handleSampleFile}
        {...props}
      >
        Sample File
      </button>
    </Tooltip>
  );
}

export default SampleFileButton;
